<template>
    <div class="contact-card">
        <div class="contact-card__inner">
            <div>
                <h3 class="contact-card__title">{{ teamMember?.title }}</h3>
                <p
                    v-if="teamMember?.function"
                    class="contact-card__department"
                >
                    {{ teamMember.function }}
                </p>
            </div>
            <div>
                <a
                    v-if="teamMember?.email"
                    :href="`mailto:${teamMember?.email}`"
                    class="contact-card__link"
                >
                    <BaseIcon icon="email" />
                    {{ teamMember.email }}
                </a>
                <a
                    v-if="teamMember?.phoneNumber"
                    :href="`tel:${teamMember?.phoneNumber}`"
                    class="contact-card__link"
                >
                    <BaseIcon icon="phone" />
                    {{ teamMember.phoneNumber }}
                </a>
                <NuxtLink
                    v-if="teamMember?.showLink"
                    :to="localePath(`/${teamMember.uri}`)"
                    class="contact-card__link"
                >
                    <BaseIcon icon="arrow-right-circle" />
                    {{ t('team.more_info') }}
                    <div class="sr-only">
                        {{ t('team.about') }} {{ teamMember?.title }}
                    </div>
                </NuxtLink>
            </div>
        </div>

        <ImageAsset
            v-if="teamMember?.image?.[0]"
            :image="teamMember.image[0]"
            sizes="(min-width: 768px) 175px, 95px"
            class="contact-card__image"
        />
    </div>
</template>

<script setup>
defineProps({
    teamMember: {
        type: Object,
        required: true
    }
});

const { t } = useI18n();
</script>

<style lang="less" src="./ContactCard.less" />
